export default function ActiveNav() {
  // Get the matching nav item containing the hash
  const getNavLink = (id) => document.querySelector('[data-id="/' + id + '"]');

  // Gather all of the nav items
  const navItems = document.querySelectorAll('.main-nav__link');


  // Create an intersection observer
  const observer = new IntersectionObserver((entries) => {

      entries.forEach(entry => {
      // Get the nav link corresponding to this entry
        const navLink = getNavLink('#' + entry.target.id);
        if (navLink != null) {
            if (entry.isIntersecting) {
                // If the entry is in the viewport, add the active class
                navLink.classList.add('main-nav__link--active');
            } else {
                // If the entry is not in the viewport, remove the active class
                navLink.classList.remove('main-nav__link--active');
            }
        }
    });
  }, {
    // Configure the observer to trigger when the entry is 50% in the viewport
    threshold: 0.5
  });

  // Observe each section
  const sections = document.querySelectorAll('.section');
  sections.forEach(section => observer.observe(section));

  // Listen for hash changes
  window.addEventListener('hashchange', () => {

    // Get current hash to find current nav link
    const currentSectionId = location.hash;

    // Get the matching nav item containing the hash
    const currentNavLink = getNavLink(currentSectionId);

    // If there isn't a nav item with the hash return
    if (!currentNavLink) return;

    // Gather all of the nav items and remove the active class
    Array.prototype.forEach.call(navItems, (navItem) => {
      navItem.classList.remove('main-nav__link--active');
    });

    // Add the active class to the currently selected nav item
    currentNavLink.classList.add('main-nav__link--active');
  });
}