import Swiper from 'swiper';
import { Navigation} from 'swiper/modules';


const SwiperAnnouncements = () => {
    const swiperAnnouncements = new Swiper('#announcements .swiper', {
        slidesPerView: 1,
        modules: [Navigation],
    });
}

export default SwiperAnnouncements;