import './polyfills.js';
/*import whatInput from 'what-input';*/
import { SuperSimpleAccordions } from 'super-simple-accordion/src';
// import ShowHide from './Components/ShowHide.js';
// import PageTabs from './Components/Tabs';
// import { flipListen } from './Components/FlipCards';
// import { dataListen, initElements } from './Components/ToggleData';
// import { menuListen } from './Components/DropdownNav';
// import ModalVideo from 'modal-video';
// import ExternaLinks from './Components/ExternalLinks';
import Swiper from 'swiper';
import {
	Navigation,
	Pagination,
	A11y,
	Keyboard,
	EffectFade,
} from 'swiper/modules';
import MicroModal from 'micromodal';
import SidebarToggle from './Components/NavSidebar';
import ActiveNav from './Components/ActiveNav';
import Sticky from './Components/Sticky';
import SwiperAnnouncements from './Components/Announcements';

// Main Menu
// if (document.querySelector("[data-nav='dropdown']")) {
//   menuListen();
// }

SidebarToggle.setup();

document.addEventListener('DOMContentLoaded', function () {
	Sticky('.hero', '.main-header');
	SwiperAnnouncements();
	ActiveNav();

	// Gallery Modal
	try {
		var modalConfig = {
			onClose: function (modal) {
				var imageElem = document.querySelector(
					`.modal__image img`,
					modal
				);
				imageElem.remove();
			},
			disableScroll: true, // [6]
			disableFocus: false, // [7]
			awaitOpenAnimation: true, // [8]
			awaitCloseAnimation: true, // [9]
			debugMode: true, // [10]
		};

		var modalTriggers = document.querySelectorAll('[data-trigger]');
		for (var i = 0; i < modalTriggers.length; i++) {
			modalTriggers[i].addEventListener('click', function (e) {
				var modalId = e.target.dataset['trigger'];

				var imageElem = document.createElement('img');
				imageElem.src = e.target.dataset['image'];

				var imageContainer = document.querySelector(
					'#' + modalId + ' .modal__image'
				);
				imageContainer.appendChild(imageElem);

				MicroModal.show(modalId, modalConfig);

				e.preventDefault();
			});
		}
	} catch (e) {
		console.log('micromodal error: ', e);
	}

	if (document.querySelector('.accordion')) {
		const accordions = new SuperSimpleAccordions('.accordion', {
			iconsClass: 'arrow',
			iconsSymbol: 'arrow', // arrow or plus-minus
			iconsPosition: 'right', // right or left
			expandAllBtn: false,
		});
	}

	//ActiveNav();

	//window.addEventListener('hashchange', function () {
	//  ActiveNav();
	//});
	// if (document.querySelector("[target='_blank']")) {
	//   ExternaLinks('[target="_blank"]');
	// }

	// Home page tabs setup
	// if (document.querySelector('.tabbed')) {
	//   PageTabs();
	// }

	// Find show hide elements, if found run setup
	// if (document.querySelector('.show-hide__toggle')) {
	//   ShowHide.setElelements();
	// }

	// Find cards that flip
	// if (document.querySelector('.details-card')) {
	//   flipListen();
	// }

	// Find data-year, if found run setup
	// if (document.querySelector('[data-year]')) {
	//   initElements();
	//   dataListen();
	// }

	//Swiper setup for home page
	if (document.querySelector('.swiper--slideshow')) {
		let videoSwiper = new Swiper('.swiper--slideshow', {
			modules: [Navigation, Pagination, A11y, Keyboard, EffectFade],
			slidesPerView: 1,
			effect: 'fade',
			preloadImages: false,
			keyboard: {
				enabled: true,
				onlyInViewport: false,
			},
			navigation: {
				nextEl: '.swiper-next',
				prevEl: '.swiper-prev',
			},
			pagination: {
				el: '.swiper-pagination',
				type: 'fraction',
			},
			a11y: {
				firstSlideMessage: 'This is the first slide',
				lastSlideMessage: 'This is the last slide',
				prevSlideMessage: 'Previous slide',
				nextSlideMessage: 'Next slide',
				itemRoleDescriptionMessage: 'Slides',
				containerMessage: 'Image slidehow',
				containerRoleDescriptionMessage: 'slideshow',
			},
		});
		//        videoSwiper.init();
	}
	if (document.querySelector('.swiper--gallery')) {
		let gallerySwiper = new Swiper('.swiper--gallery', {
			modules: [Navigation, Pagination, A11y, Keyboard, EffectFade],
			slidesPerView: 'auto',
			spaceBetween: 12,
			keyboard: {
				enabled: true,
				onlyInViewport: false,
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		});

		gallerySwiper.init();
	}

	// var modalTriggers = document.querySelectorAll(
	//   '[data-video-id],[data-youtube-id]'
	// );
	// for (var i = 0; i < modalTriggers.length; i++) {
	//   modalTriggers[i].addEventListener('click', function (e) {
	//     e.preventDefault();
	//   });
	// }

	// new ModalVideo('[data-video-id]', {
	//   vimeo: {
	//     byline: false,
	//     title: false,
	//   },
	// });
});
