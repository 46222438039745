export default function StickyHeader(sentinal, header) {
  const headerElem = document.querySelector(header);
  const sentinalElem = document.querySelector(sentinal);

  let options = {
    threshold: 0.5,
  };

  if (!sentinalElem) return;

  const handler = (entries) => {
    // entries is an array of observed dom nodes
    // we're only interested in the first one at [0]
    // because that's our .sentinal node.
    // Here observe whether or not that node is in the viewport
    if (!entries[0].isIntersecting) {
      headerElem.classList.add('stuck');
      setTimeout(function () {
        headerElem.classList.add('shrink');
      }, 250);
    } else {
      headerElem.classList.remove('stuck');
      setTimeout(function () {
        headerElem.classList.remove('shrink');
      }, 250);
    }
  };

  // create the observer
  const observer = new window.IntersectionObserver(handler, options);
  // give the observer some dom nodes to keep an eye on
  observer.observe(sentinalElem);
}
